export const DIGITAL_SIGNATURE_STATE = {
  NO_DIGITAL_SIGNATURE: 'NO_DIGITAL_SIGNATURE',
  DRAFT: 'DRAFT',
  STARTED: 'STARTED',
  REVOKED: 'REVOKED',
  SIGNED: 'SIGNED',
  TERMINATED: 'TERMINATED',
};

export const changeDigitalSignatureStateToStarted = (shoppingCart) => {
  const newShoppingCart = { ...shoppingCart };
  if (shoppingCart.digitalSignatureState !== DIGITAL_SIGNATURE_STATE.DRAFT && shoppingCart.digitalSignatureState !== DIGITAL_SIGNATURE_STATE.REVOKED) throw new Error('ShoppingCart has to have the state DRAFT or REVOKED to get into the state STARTED!');
  newShoppingCart.digitalSignatureState = DIGITAL_SIGNATURE_STATE.STARTED;
  return newShoppingCart;
};

export const changeDigitalSignatureStateToRevoked = (shoppingCart) => {
  const newShoppingCart = { ...shoppingCart };
  if (shoppingCart.digitalSignatureState !== DIGITAL_SIGNATURE_STATE.STARTED) throw new Error('ShoppingCart has to have the state STARTED to get into the state REVOKED!');
  newShoppingCart.digitalSignatureState = DIGITAL_SIGNATURE_STATE.REVOKED;
  return newShoppingCart;
};

export const changeDigitalSignatureStateToSigned = (shoppingCart) => {
  const newShoppingCart = { ...shoppingCart };
  if (shoppingCart.digitalSignatureState !== DIGITAL_SIGNATURE_STATE.STARTED) throw new Error('ShoppingCart has to have the state STARTED to get into the state SIGNED!');
  newShoppingCart.digitalSignatureState = DIGITAL_SIGNATURE_STATE.SIGNED;
  return newShoppingCart;
};

export const changeDigitalSignatureStateToTerminated = (shoppingCart) => {
  const newShoppingCart = { ...shoppingCart };
  if (shoppingCart.digitalSignatureState !== DIGITAL_SIGNATURE_STATE.SIGNED) throw new Error('ShoppingCart has to have the state SIGNED to get into the state TERMINATED!');
  newShoppingCart.digitalSignatureState = DIGITAL_SIGNATURE_STATE.TERMINATED;
  return newShoppingCart;
};
