import { isNumber, isFinite } from 'lodash';
import { HighlightBindingness } from '.';
import { nbsp } from '../constants';
import { ShowDigits } from '../utils';
import { formatCurrency } from './calcUtils';
import { CalculationMode } from './constants';

const checkIsDiscount = (value) => {
  if (value) {
    const isCustomPrice = isFinite(value.customPrice);
    const priceBeforeDiscount = value[isCustomPrice ? 'customPrice' : 'priceBeforeDiscount'];
    const discountedValue = value[isCustomPrice ? 'discountedCustomPrice' : 'discountedValue'];
    return discountedValue !== priceBeforeDiscount && isNumber(discountedValue) && isNumber(priceBeforeDiscount);
  }
  return false;
};

export const priceInfoAsText = ({ paymentInterval, discount, hideInterval, showDiscounts = true, currentFeeTypeMonthly, showDigits = ShowDigits.avoid, showCalculatedPrice, value, calculationMode = CalculationMode.VALUE, highlightBindingness = HighlightBindingness.NO_HIGHLIGHT, isTotalPrice }) => {
  if (calculationMode === CalculationMode.ON_ACTUAL_COST)
    return {
      code: 'code_on_actual_cost',
      variables: {
        paymentInterval,
        calculationMode,
      },
    };

  if (currentFeeTypeMonthly && (paymentInterval === 'monthly' || paymentInterval === 'yearly'))
    return {
      code: `fixedMonthlyFeeHint${discount?.value ? '_discount' : ''}`,
      variables: {
        paymentInterval,
        highlightBindingness: calculationMode === CalculationMode.ON_ACTUAL_COST ? HighlightBindingness.NO_HIGHLIGHT : highlightBindingness,
      },
    };

  const { customPrice, discountedCustomPrice, discountedValue, priceBeforeDiscount } = value ?? {};
  const formatCurrencyOptions = { showDigits };
  const formattedDiscount = discount?.value && (discount?.type === 'percent' ? `${discount?.value}${nbsp}%` : formatCurrency(discount?.value));

  const isCustomPrice = isFinite(customPrice);
  if (!isTotalPrice && !isCustomPrice && !discountedValue)
    return {
      code: `free_service`,
      variables: {
        highlightBindingness,
        paymentInterval,
      },
    };

  const isDiscounted = checkIsDiscount(value);
  const variables = {
    customPrice: formatCurrency(customPrice, formatCurrencyOptions),
    discount: formattedDiscount,
    discountedCustomPrice: formatCurrency(discountedCustomPrice, formatCurrencyOptions),
    discountedValue: formatCurrency(discountedValue, formatCurrencyOptions),
    discountedValueRaw: discountedValue,
    hideInterval: !!hideInterval,
    isDiscounted,
    paymentInterval: hideInterval ? '' : paymentInterval,
    priceBeforeDiscount: formatCurrency(priceBeforeDiscount, formatCurrencyOptions),
    priceBeforeDiscountRaw: priceBeforeDiscount,
    highlightBindingness,
  };

  let code = 'code';
  /*
  When is it added?
  - when there is a customPrice (in shopping cart => category => item)
  */
  if (isCustomPrice) code += '_customPrice';
  /*
  When is it added?
  - when the showCalculatedPrice switch is on (where customPrice is set in shopping cart => category => item)
  */
  if (isCustomPrice && showCalculatedPrice) code += '_showCalculatedPrice';
  /*
  When is it added?
  - when a discount is selected for this field
  Ignore:
  - if showDiscounts (shopping cart view options button) is false.
  */
  if (showDiscounts && isDiscounted) code += '_discount';
  /*
  When is it added?
  - when discounts have not value (calculate shopping cart total)
  Ignore:
  - when there is a customPrice
  - if showDiscounts or isDiscounted are false.
  */
  if (!isCustomPrice && showDiscounts && isDiscounted && !discount?.value) code += '_noDiscountValue';

  return { code, variables };
};
