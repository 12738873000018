import { formatFormula, richTextToFormula } from './mentions';
import { numberToLocaleString } from './utils';
import * as RichText from './richText';

/**
 * @typedef {Object} StaticServiceData
 * @property {string} nameRaw
 * @property {string} valueRaw
 */

/**
 * StaticService class
 * Handles static service logic
 */
export class StaticService {
  staticServiceData = {};

  /**
   * Construct static service object with the data of the static service
   * @param {StaticServiceData} staticServiceData - Data of the static service
   */
  constructor(staticServiceData) {
    this.staticServiceData = staticServiceData;
  }

  /**
   * Get the value of the static service as readable text
   * Variables are rendered with the name of the variable
   * @param {object[]} variableArray - Array of variables
   * @param {string} variableArray._id - The id of the variable
   * @param {string} variableArray.name - The name of the variable
   * @returns {string} - The value of the static service as readable text
   */
  getValueAsReadableText(variableArray) {
    return formatFormula({
      formula: richTextToFormula(this.staticServiceData.valueRaw.replaceAll('net_', '')),
      getInputFields: async () => {
        return variableArray;
      },
    });
  }

  /**
   * Get the value of the static service as numbered readable text
   * Variables are rendered with the values of the variable
   * Brutto variables are shown as brutto values, when vat is shown
   * Numbers, which aren't wrapped in text functions, are shown as brutto numbers
   * @param {boolean} showVat - Should the value be a brutto value
   * @param {boolean} showDigits - Should the digits after the comma be shown, if they are 0
   * @param {number} vatValue - The value of the vat (as 0.19 for 19% vat)
   * @param {string} locale - The locale of the number format ('de' | 'en')
   * @param {object} placeholderObject - Placeholder object with set placeholders for the variables and net variables
   * @returns {string} - The value of the static service as numbered readable text
   */
  getValueAsNumberedReadableText(showVat, showDigits, vatValue, locale, placeholderObject) {
    const modifiedValue = this.replaceNumbersByBruttoValues(showVat, showDigits, vatValue, locale);

    return RichText.richEditorTextToText(modifiedValue, placeholderObject);
  }

  /**
   * Get the value with unwrapped numbers as brutto values, when show vat is enabled
   * Numbers, which aren't wrapped in text functions, are shown as brutto numbers
   * @param {boolean} showVat - Should the value be a brutto value
   * @param {boolean} showDigits - Should the digits after the comma be shown, if they are 0
   * @param {number} vatValue - The value of the vat (as 0.19 for 19% vat)
   * @param {string} locale - The locale of the number format ('de' | 'en')
   * @param {object} placeholderObject - Placeholder object with set placeholders for the variables and net variables
   * @returns {string} - The value of the static service as richtext json string with numbers replaced by brutto/net values
   */
  replaceNumbersByBruttoValues(showVat, showDigits, vatValue, locale) {
    let modifiedValue = this.staticServiceData.valueRaw;
    const valueRichtext = JSON.parse(modifiedValue);

    const numberRegex = /([+-]?\d+(?:[.,]\d+)?)/g;

    valueRichtext[0].children = valueRichtext[0].children.map((child) => {
      if (!child.text) return child;

      let modifiedChildText = child.text;
      const numbers = child.text.match(numberRegex);
      if (numbers && numbers.length) {
        numbers.forEach((number) => {
          // eslint-disable-next-line no-new-wrappers
          const numberToReplace = new Number(number.replace(',', '.'));
          const numberToReplaceWith = showVat ? numberToLocaleString(numberToReplace + numberToReplace * vatValue, locale, { showDigits }) : numberToLocaleString(numberToReplace, locale, { showDigits });
          modifiedChildText = modifiedChildText.replace(number, numberToReplaceWith);
        });
        return { text: modifiedChildText };
      }
      return child;
    });

    modifiedValue = JSON.stringify(valueRichtext);
    return modifiedValue;
  }

  /**
   * Get the name of the static service as readable text
   * Variables are rendered with the name of the variable
   * @param {object[]} variableArray - Array of variables
   * @param {string} variableArray._id - The id of the variable
   * @param {string} variableArray.name - The name of the variable
   * @returns {string} - The name of the static service as readable text
   */
  getNameAsReadableText(variableArray) {
    return formatFormula({
      formula: richTextToFormula(this.staticServiceData.nameRaw.replaceAll('net_', '')),
      getInputFields: async () => {
        return variableArray;
      },
    });
  }
}
