/* eslint-disable prefer-regex-literals */
import { sortBy } from 'lodash';
import parser from './parser';
import { calcWithRawFormula, removeBrackets, richTextToFormula } from '../mentions/utils';

const scaleDeclaration = `scale(${new Array(5)
  .fill(null)
  .map((e, i) => `inputValue${i + 1}`)
  .join(',')})=`;

export const parseExpression = (expression) => {
  if (!expression || !expression.trim()) throw new Error('empty_pricing_formula');

  let functions = '';
  // eslint-disable-next-line no-param-reassign
  if (expression.includes('/*functions end*/')) [functions, expression] = expression.split('/*functions end*/');

  const exprToParser = (expr) =>
    expr
      .replace(/@/gi, '')
      .replace(new RegExp('&gt;', 'ig'), '>')
      .replace(new RegExp('&lt;', 'ig'), '<')
      .replace(new RegExp('%', 'g'), '/100') // allow to write percent symbol
      .replace(/(\d),(\d)/g, '$1.$2'); // allow to write comma

  const functionsToParser = exprToParser(functions);
  const expressionToParser = exprToParser(expression).replace(/;/g, ',');
  const expr = `${functionsToParser} ${expressionToParser}`;
  return parser.parse(expr);
};

export const calc = ({ variables, expression, minPrice }) => {
  const expr = parseExpression(expression);
  try {
    const value = expr.evaluate(variables);
    return typeof minPrice === 'number' && !Number.isNaN(minPrice) ? Math.max(value, minPrice) : value;
  } catch (e) {
    if (e.message?.includes('undefined variable: ')) {
      e.code = 'variable_is_empty';
      e.variable = e.message.replace('undefined variable: ', '');
      e.oldMessage = e.message;
      e.message = `@${e.variable} is empty`;
      e.expression = expression;
      // console.log(expressionStringToParser);
    }
    throw e;
  }
};
export const applyDiscountToValue = ({ value, discount }) => {
  if (!discount || value < 0) return value;
  const discountType = discount.type || 'percent';
  const discountValue = discountType === 'percent' ? value * (discount.value / 100) : discount.value;
  const discountedValue = Math.max(value - (discountValue || 0), 0);
  return discountedValue;
};
export const calcWithDiscount = ({ variables, expression, discount, minPrice }) => {
  let value = 0;
  let discountedValue = 0;
  if (expression) {
    value = calcWithRawFormula({ variables, expression, minPrice });
    discountedValue = applyDiscountToValue({ value, discount });
  }
  return { value, discountedValue };
};

export const graduatedScalesToFormula = (arr) => {
  return (
    scaleDeclaration +
    sortBy(arr, 'value')
      .map((curr, index, sortedArr) => {
        let formula = '';
        const prevValue = sortedArr[index - 1]?.value || 0;
        const currentPricingFormula = removeBrackets(richTextToFormula(curr.pricingFormula, { hideMentionText: true }));
        if (index === arr.length - 1) {
          formula = `(inputValue1 - ${prevValue})`;
        } else {
          const currentValue = sortedArr[index]?.value || 0;
          formula = `(inputValue1 >= ${currentValue} ? (${currentValue} - ${prevValue}) : inputValue1-${prevValue})`;
        }
        return `(${currentPricingFormula} * (inputValue1 <= ${prevValue} ? 0 : ${formula}))`;
      })
      .join('+')
  );
};

export const scalesToFormula = (arr) =>
  sortBy(arr, 'value').reduce((prev, curr, index) => {
    const value = prev.value || prev;
    const currentPricingFormula = removeBrackets(richTextToFormula(curr.pricingFormula, { hideMentionText: true }));
    if (index === arr.length - 1) return `${value}(${currentPricingFormula})`;
    return `${value}(inputValue1<=${curr.value})?(${currentPricingFormula}): (`;
  }, scaleDeclaration) + new Array(arr.length - 1).fill(')').join('');
