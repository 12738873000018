/* eslint-disable prefer-regex-literals */
import { findIndex, isNumber, sortBy, isInteger, escapeRegExp } from 'lodash';

const checkForHexRegExp = new RegExp('^[0-9a-fA-F]{24}$');

export const isValidObjectId = (id) => {
  if (id == null) return false;

  if (typeof id === 'number') {
    return true;
  }

  if (typeof id === 'string') {
    return id.length === 12 || (id.length === 24 && checkForHexRegExp.test(id));
  }
  return false;
};
export const ShowDigits = {
  always: 'always',
  avoid: 'avoid',
};
export const numberToLocaleString = (number, locale, { showDigits = ShowDigits.avoid, isRestrictMaximumDigits = true } = {}) => {
  if (locale) {
    let minimumFractionDigits;
    if (showDigits === ShowDigits.avoid) {
      minimumFractionDigits = isInteger(+number) ? 0 : 2;
    } else if (showDigits === ShowDigits.always) {
      minimumFractionDigits = 2;
    }
    return (+number).toLocaleString(locale, {
      maximumFractionDigits: isRestrictMaximumDigits ? 2 : 20,
      minimumFractionDigits,
    });
  }
  return `${+number}`;
};

/**
 * example: getValueText([{id: 1, value: 3}, {id: 2, value: 5}], {id: 2, value: 5}) => '3-5'
 * @param {[Object]} values - all objects
 * @param {Object} value - object with the right boundary of the interval
 * @returns {string} interval for example '4-7', '> 7' and empty string if it is not possible to construct an interval
 */
export const getScaleRange = (_values, value, locale, options) => {
  const values = sortBy(_values, 'value');
  const index = findIndex(values, value);
  if (index === -1) return '';
  const rightLimit = values[index]?.value;
  const leftLimit = values[index - 1]?.value;
  if (!isNumber(rightLimit) && !isNumber(leftLimit)) return '';
  if (!isNumber(leftLimit)) {
    if (rightLimit < 1) return '1';
    return `1 - ${numberToLocaleString(rightLimit, locale, options)}`;
  }
  if (!isNumber(rightLimit)) return `${numberToLocaleString(leftLimit + 1, locale, options)}+`;
  if (leftLimit + 1 === rightLimit) return `${numberToLocaleString(rightLimit, locale, options)}`;
  return `${numberToLocaleString(leftLimit + 1, locale, options)} - ${numberToLocaleString(rightLimit, locale, options)}`;
};

export const RenderIf = ({ value, children, fallback = null }) => {
  if (value)
    if (typeof children === 'function') return children(value);
    else return children;
  return fallback;
};

export const getDecimalSeparator = (t) => {
  const ifComma = t('decimalSeparator') === ',';
  return ifComma ? { decimalSeparator: ',', thousandSeparator: '.' } : { decimalSeparator: '.', thousandSeparator: ',' };
};

export const getNumberFormat = ({ decimalSeparator, thousandSeparator }) => {
  // 23456789.345 => 23${thousandSeparator}456${thousandSeparator}789${decimalSeparator}345
  const escapedDecimalSeparator = escapeRegExp(decimalSeparator);
  const escapedThousandSeparator = escapeRegExp(thousandSeparator);
  const jsDot = '.'; // string value of dot in js Number
  const escapedJsDot = escapeRegExp(jsDot);
  const thousandSeparatorRegExp = new RegExp(escapedThousandSeparator, 'g'); // match 'thousandSeparator'
  const dotRegExp = new RegExp(`${escapedJsDot}`, 'g'); // match '.' followed by a number but not include number
  const decimalRegExp = new RegExp(`${escapedDecimalSeparator}`, 'g'); // match 'decimalSeparator' followed by a number but not include number
  const eachThreeNumberRegExp = new RegExp('\\B(?=(\\d{3})+(?!\\d))', 'g'); // match thousands separator place (regex from https://ant.design/components/input-number/)
  const eachThreeNumberRegExpIfExistComma = new RegExp(`\\B(?=(\\d{3})+(?!\\d)${escapedDecimalSeparator})`, 'g'); // match thousands separator place until decimalSeparator appears (modified version of eachThreeNumberRegExp)
  const formatter = (value) => {
    // js number to string
    const v = `${value}`; // to string
    const valueWithDecimalUserWants = `${value}`.replace(dotRegExp, decimalSeparator); // replace jsDot to custom decimal separator
    const valueSplittedByThousands = valueWithDecimalUserWants.replace(v.includes(jsDot) ? eachThreeNumberRegExpIfExistComma : eachThreeNumberRegExp, thousandSeparator); // split value by thousands
    return valueSplittedByThousands;
  };
  const parser = (value) => {
    // parse formatted user's number string to `${number}`
    const removedThousandsSeparator = value.replace(thousandSeparatorRegExp, ''); // remove thousands separator
    const valueWithJSDot = removedThousandsSeparator.replace(decimalRegExp, jsDot); // use js Number dot
    return valueWithJSDot;
  };
  return { formatter, parser };
};
