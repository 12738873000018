import { camelCase, escapeRegExp, mapKeys, mapValues, omit, uniq, upperFirst } from 'lodash';
import { calc, scalesToFormula, graduatedScalesToFormula } from '../calc';
import { isValidObjectId } from '../utils';
import { mentionTypes } from './constants';

export const richTextToFormula = (rt, { hideMentionText = false } = {}) => {
  // eslint-disable-next-line no-param-reassign
  if (!rt) return '';
  // eslint-disable-next-line no-param-reassign
  // if (typeof rt !== 'string') rt = '';
  // eslint-disable-next-line no-param-reassign
  if (rt?.startsWith?.('[{"type')) rt = JSON.parse(rt);
  if (!Array.isArray(rt)) return '';
  const str = rt[0].children
    .reduce((prev, curr) => {
      if (curr.type === 'mention') {
        if (curr.mentionType === 'formula_reference') return [...prev, `@{{${curr._id}}}`];
        if (curr.mentionType === 'formula_function') {
          let value;
          if (curr._id === 'text' && hideMentionText) value = '';
          else if (curr._id === 'text')
            value = curr.children
              .map((e) => e.text)
              .filter(Boolean)
              .join('')
              .trim();
          else value = `#{{${curr._id}}}`;
          return [...prev, value];
        }
        if (curr.mentionType === 'variable') return [...prev, `@{{${curr.name}}}`];
      }
      if (curr.text) return [...prev, curr.text.trim()];
      return prev;
    }, [])
    .join(' ')
    .trim();
  return str;
};

export const typeRegExp = (type) => new RegExp(`${type}{{(?:\\s+)?(.*?)(?:\\s+)?}}`, 'g');
export const getInputFieldIdsFromFormula = (formula) => {
  const fieldIds = [];
  if (!formula) return [];
  formula.replace(typeRegExp(mentionTypes[0].type), (match) => {
    const matched = match.substring(3, match.length - 2);
    fieldIds.push(matched);
  });
  return uniq(fieldIds);
};
export const replaceFormulasIdsWithNames = (fields, formula) => fields.reduce((prev, { _id, formulaName }) => prev.replace(new RegExp(escapeRegExp(`@{{${_id}}}`), 'g'), `@{{${formulaName}}}`).replace(new RegExp(escapeRegExp(`#{{${_id}}}`), 'g'), `#{{${formulaName}}}`), formula);

export const nameToFormulaCase = (name) => upperFirst(camelCase(name));
export const removeBrackets = (formula, { leaveTypeSymbol, customFunction } = {}) =>
  mentionTypes.reduce(
    (prev, { type }) =>
      prev.replace(typeRegExp(type), (match) => {
        const name = match.substring(3, match.length - 2);
        if (customFunction) return customFunction({ name, type });
        return `${leaveTypeSymbol ? type : ''}${name}`;
      }),
    formula,
  );

export const addFormulaNameToFields = (fields) =>
  fields.map((f) => ({
    ...f,
    formulaName: f.formulaName || f.name,
  }));

export const formatFormula = ({ formula, getInputFields }) => {
  try {
    const ids = getInputFieldIdsFromFormula(formula).filter(isValidObjectId);
    const fieldsMaybePromise = getInputFields(ids);
    const format = (fieldsArray) => {
      const inputFields = addFormulaNameToFields(fieldsArray);
      const formulaWithNames = replaceFormulasIdsWithNames(inputFields, formula);
      return removeBrackets(formulaWithNames);
    };
    if (fieldsMaybePromise.then) return fieldsMaybePromise.then((fieldsResolved) => format(fieldsResolved));
    return format(fieldsMaybePromise);
  } catch (e) {
    console.error(e);
  }
  return formula;
};

export const prepareValuesToCalc = (values) => {
  const mappedValues = mapValues(values, (value) => {
    // input field combobox
    const stringValue = String(value);
    let currentValue = value;
    if (stringValue.length === 24 && stringValue in values) currentValue = values[stringValue];
    if (currentValue === null || Number.isNaN(currentValue)) return undefined;
    return currentValue;
  });
  return mapKeys(mappedValues, (value, key) => `V_${key}`);
};
export const prepareFormulaToCalc = (formula) => {
  return removeBrackets(formula, { customFunction: ({ name, type }) => (type === '@' ? `V_${name}` : name) });
};

export const calcWithRawFormula = ({ expression, variables: v, minPrice }) => {
  const formulaForCalc = prepareFormulaToCalc(expression);
  const variables = prepareValuesToCalc(v);
  return calc({ variables, expression: formulaForCalc, minPrice });
};

export const isValidFormula = (expression) => {
  if (!expression) throw new Error('formula can not be empty');
  const variables = getInputFieldIdsFromFormula(expression).reduce((prev, curr) => ({ ...prev, [curr]: 1 }), {});
  calcWithRawFormula({ variables, expression });
};

export const getItemFormulaWithScales = ({ item, pricingFormula = item?.pricingFormula, scales = item?.scales || [], graduatedScaleMode = item?.scales || false }) => {
  const pricingFormula_ = richTextToFormula(pricingFormula, { hideMentionText: true });
  if (!scales?.length || !pricingFormula_) return pricingFormula_;
  if (graduatedScaleMode) {
    const scalesFunction = graduatedScalesToFormula(scales);
    return `${scalesFunction}; /*functions end*/ ${pricingFormula_}`;
  }
  const scalesFunction = scalesToFormula(scales);
  return `${scalesFunction}; /*functions end*/ ${pricingFormula_}`;
};

function findMention(node, predicate, normalizeMention) {
  if (node.type === 'mention' && predicate(node)) return normalizeMention(node);
  // eslint-disable-next-line no-param-reassign
  if ('children' in node) node.children = node.children.map((child) => findMention(child, predicate, normalizeMention));
  return node;
}

function normalizeMentions({ value, predicate = (e) => e, normalizeMention, toJsObject }) {
  const formulaObject = JSON.parse(value);
  const newValue = Array.isArray(formulaObject) ? formulaObject.map((node) => findMention(node, predicate, normalizeMention)) : findMention(formulaObject, predicate, normalizeMention);
  if (toJsObject) return newValue;
  return JSON.stringify(newValue);
}

export const generateTextNode = (value) => ({ text: `${value}` });

export const generateDeletedMention = (node) =>
  node?.mentionType === 'deleted'
    ? node
    : {
        ...omit(node, ['_id', 'name']),
        mentionType: 'deleted',
        oldMentionType: node.mentionType,
      };

export const replaceMentions = ({ value, predicate, newValue, normalizeMention: _normalizeMention, toJsObject }) => {
  const normalizeMention = (node) => (typeof _normalizeMention === 'function' ? _normalizeMention(node) : generateTextNode(newValue));
  return normalizeMentions({ value, predicate, normalizeMention, toJsObject });
};
