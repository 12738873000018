export const ListTypeNames = {
  NUMBERED_LIST: 'numbered-list',
  BULLETED_LIST: 'bulleted-list',
  CHECKED_LIST: 'checked-list',
};

export const listTypes = {
  [ListTypeNames.NUMBERED_LIST]: ['1', 'a', 'i'],
  [ListTypeNames.BULLETED_LIST]: ['disc', 'circle', 'square'],
  [ListTypeNames.CHECKED_LIST]: [],
};

export const LIST_TYPES = Object.values(ListTypeNames);

export const getNextListType = (list) => {
  const types = listTypes[list?.type];
  if (!types) return null;
  const indexOfListType = types.indexOf(list.listType);
  if (indexOfListType === -1) return types[0];
  return types[(indexOfListType + 1) % types.length];
};

const mergeLists = ({ lists, listSharedData }) => {
  const [innerParent, ...innerChildren] = lists;
  return {
    ...innerParent,
    ...listSharedData,
    children: [...innerParent.children, ...innerChildren.map((c) => c.children).flat()],
  };
};
export function normalizeNode({ node, parentList, level = 1, parent }, { rootElement }) {
  const newNode = { ...node };
  let nextParentList = parentList;
  if (node.type === 'list-item' && !parentList && !parent) newNode.type = rootElement;
  if (LIST_TYPES.includes(node.type)) {
    nextParentList = node;
    newNode.level = newNode.level ?? level;
    const prevType = parentList?.type;
    const currentType = node?.type;
    const listType = node?.listType;
    // if a child of current list is not list-item or list
    // then
    // change child type to list-item
    node.children.forEach((child) => {
      // eslint-disable-next-line no-param-reassign
      if (![...LIST_TYPES, 'list-item'].includes(child.type)) child.type = 'list-item';
    });
    // if current and parent types are equal
    // then
    // if their order are wrong, change the current list type
    if (prevType === currentType) {
      const correctNextValue = getNextListType({ type: prevType, listType: parentList?.listType });
      if (correctNextValue !== listType) newNode.listType = correctNextValue;
    }
    // if current and parent types are different or parent list is missing
    // then
    // if the current list type is wrong, change the current list type
    else if (currentType && (!listType || listType !== listTypes[currentType][0])) {
      const correctNextValue = listTypes[currentType][0];
      newNode.listType = correctNextValue;
    }
  }
  if (newNode.children) {
    if (nextParentList) {
      let lists = [];
      let listSharedData;
      newNode.children = newNode.children
        .map((child, i, arr) => {
          const nextChild = arr[i + 1];
          if (LIST_TYPES.includes(child.type)) {
            if (!listSharedData)
              listSharedData = {
                type: child.type,
                listType: child.listType,
              };
            lists.push(child);
            if (!LIST_TYPES.includes(nextChild?.type)) {
              const newChild = mergeLists({ lists, listSharedData });
              lists = [];
              return newChild;
            }
            return null;
          }
          return child;
        })
        .filter(Boolean);
    }
    newNode.children = newNode.children.flat().map((child) => normalizeNode({ node: child, parentList: nextParentList, parent: newNode, level: level + 1 }, { rootElement }));
    // if current type is not list and contains the least list-item
    // then
    // return children
    if (!LIST_TYPES.includes(newNode.type) && newNode.children.find((child) => child.type === 'list-item')) return newNode.children;
  }

  return newNode;
}

export function normalizeValue(valueJSON, options) {
  const value = JSON.parse(valueJSON);
  const newValueJSON = JSON.stringify(Array.isArray(value) ? value.map((node) => normalizeNode({ node }, options)) : normalizeNode({ node: value }, options));
  if (valueJSON === newValueJSON) return newValueJSON;
  return normalizeValue(newValueJSON, options);
}
