import parser from './parser';

export const StBVV_TabelleA = (inputValue, minimalInputValue) => {
  if (minimalInputValue !== undefined && inputValue < minimalInputValue) return StBVV_TabelleA(minimalInputValue);
  const inputParam = [300, 600, 900, 1200, 1500, 2000, 2500, 3000, 3500, 4000, 4500, 5000, 6000, 7000, 8000, 9000, 10000, 13000, 16000, 19000, 22000, 25000, 30000, 35000, 40000, 45000, 50000, 65000, 80000, 95000, 110000, 125000, 140000, 155000, 170000, 185000, 200000, 230000, 260000, 290000, 320000, 350000, 380000, 410000, 440000, 470000, 500000, 550000, 600000, 5000000, 25000000];
  const outputValue = [29, 53, 76, 100, 123, 157, 189, 222, 255, 288, 321, 354, 398, 441, 485, 528, 571, 618, 665, 712, 759, 806, 892, 977, 1061, 1146, 1230, 1320, 1411, 1502, 1593, 1683, 1773, 1864, 1954, 2045, 2136, 2275, 2414, 2552, 2697, 2760, 2821, 2882, 2939, 2995, 3051, 3132, 3211, 3211 + Math.ceil((inputValue - 600000) / 50000) * 141, 15619 + Math.ceil((inputValue - 5000000) / 50000) * 106, 58019 + Math.ceil((inputValue - 25000000) / 50000) * 83];
  let i = 0;
  while (i < inputParam.length && inputParam[i] < inputValue) i += 1;
  return outputValue[i];
};

export const StBVV_TabelleB = (inputValue, minimalInputValue) => {
  if (minimalInputValue !== undefined && inputValue < minimalInputValue) return StBVV_TabelleB(minimalInputValue);
  // eslint-disable-next-line prettier/prettier
  const inputParam = [3000, 3500, 4000, 4500, 5000, 6000, 7000, 8000, 9000, 10000, 12500, 15000, 17500, 20000, 22500, 25000, 37500, 50000, 62500, 75000, 87500, 100000, 125000, 150000, 175000, 200000, 225000, 250000, 300000, 350000, 400000, 450000, 500000, 625000, 750000, 875000, 1000000, 1250000, 1500000, 1750000, 2000000, 2250000, 2500000, 3000000, 3500000, 4000000, 4500000, 5000000, 7500000, 10000000, 12500000, 15000000, 17500000, 20000000, 22500000, 25000000, 30000000, 35000000, 40000000, 45000000, 50000000, 125000000, 250000000]
  // eslint-disable-next-line prettier/prettier
  const outputValue = [46, 54, 64, 72, 81, 91, 99, 109, 114, 120, 126, 142, 157, 168, 180, 190, 203, 248, 286, 319, 333, 348, 399, 444, 483, 517, 549, 578, 605, 657, 704, 746, 785, 822, 913, 991, 1062, 1126, 1249, 1357, 1455, 1542, 1621, 1695, 1841, 1971, 2089, 2196, 2566, 2983, 3321, 3603, 3843, 4050, 4314, 4558, 5014, 5433, 5823, 6187, 6532, 6532 + Math.ceil((inputValue - 50000000) / 5000000) * 258, 6532 + 11250 + Math.ceil((inputValue - 125000000) / 12500000) * 450, 6532 + 11250 + 4500 + Math.ceil((inputValue - 250000000) / 25000000) * 642];
  let i = 0;
  while (i < inputParam.length && inputParam[i] < inputValue) i += 1;
  return outputValue[i];
};

export const StBVV_TabelleC = (inputValue, minimalInputValue) => {
  if (minimalInputValue !== undefined && inputValue < minimalInputValue) return StBVV_TabelleC(minimalInputValue);
  const inputParam = [15000, 17500, 20000, 22500, 25000, 30000, 35000, 40000, 45000, 50000, 62500, 75000, 87500, 100000, 125000, 150000, 200000, 250000, 300000, 350000, 400000, 450000, 500000];
  const outputValue = [68, 75, 83, 88, 95, 102, 110, 115, 122, 130, 137, 149, 164, 177, 197, 217, 259, 299, 339, 381, 416, 448, 483, 483 + Math.ceil((inputValue - 500000) / 50000) * 34];
  let i = 0;
  while (i < inputParam.length && inputParam[i] < inputValue) i += 1;
  return outputValue[i];
};

export const StBVV_TabelleD_a = (inputValue, minimalInputValue) => {
  if (minimalInputValue !== undefined && inputValue < minimalInputValue) return StBVV_TabelleD_a(minimalInputValue);
  const inputParam = [40, 45, 50, 55, 60, 65, 70, 75, 80, 85, 90, 95, 100, 110, 120, 130, 140, 150, 160, 170, 180, 190, 200, 210, 220, 230, 240, 250, 260, 270, 280, 290, 300, 320, 340, 360, 380, 400, 420, 440, 460, 480, 500, 520, 540, 560, 580, 600, 620, 640, 660, 680, 700, 750, 800, 850, 900, 950, 1000, 2000, 3000, 4000, 5000, 6000, 7000, 8000, 9000, 10000, 11000, 12000];
  // eslint-disable-next-line prettier/prettier
  const outputValue = [348, 373, 396, 419, 441, 461, 479, 497, 514, 530, 543, 556, 567, 595, 622, 648, 674, 700, 725, 748, 772, 794, 816, 838, 859, 879, 898, 917, 936, 954, 970, 987, 1002, 1035, 1067, 1100, 1130, 1160, 1191, 1220, 1248, 1275, 1301, 1329, 1355, 1380, 1404, 1429, 1453, 1475, 1497, 1519, 1538, 1586, 1628, 1664, 1695, 1719, 1738, 1738 + (inputValue - 1000) * 1.59, 3328 + (inputValue - 2000) * 1.44, 4768 + (inputValue - 3000) * 1.3, 6068 + (inputValue - 4000) * 1.15, 7218 + (inputValue - 5000) * 1.01, 8228 + (inputValue - 6000) * 0.87, 9098 + (inputValue - 7000) * 0.72, 9818 + (inputValue - 8000) * 0.57, 10388 + (inputValue - 9000) * 0.43, 10818 + (inputValue - 10000) * 0.28, 11098 + (inputValue - 11000) * 0.15, 11248 + (inputValue - 12000) * 0.15];
  let i = 0;
  while (i < inputParam.length && inputParam[i] < inputValue) i += 1;
  return outputValue[i];
};

export const StBVV_TabelleD_b = (inputValue, minimalInputValue) => {
  if (minimalInputValue !== undefined && inputValue < minimalInputValue) return StBVV_TabelleD_b(minimalInputValue);
  // eslint-disable-next-line prettier/prettier
  const inputParam = [40000, 42500, 45000, 47500, 50000, 55000, 60000, 65000, 70000, 75000, 80000, 85000, 90000, 95000, 100000, 105000, 110000, 115000, 120000, 125000, 130000, 135000, 140000, 145000, 150000, 155000, 160000, 165000, 170000, 175000, 180000, 185000, 190000, 195000, 200000, 205000, 210000, 215000, 220000, 225000, 230000, 235000, 240000, 245000, 250000, 255000, 260000, 265000, 270000, 275000, 280000, 285000, 290000, 295000, 300000, 305000, 310000, 315000, 320000, 325000, 330000, 335000, 340000, 345000, 350000, 355000, 360000, 365000, 370000, 375000, 380000, 385000, 390000, 395000, 400000, 410000, 420000, 430000, 440000, 450000, 460000, 470000, 480000, 490000, 500000];
  // eslint-disable-next-line prettier/prettier
  const outputValue = [362, 380, 398, 417, 433, 469, 503, 539, 571, 606, 640, 673, 706, 738, 771, 802, 833, 866, 897, 927, 959, 989, 1020, 1051, 1081, 1111, 1141, 1172, 1201, 1230, 1260, 1289, 1318, 1347, 1376, 1406, 1434, 1462, 1491, 1520, 1547, 1575, 1603, 1630, 1656, 1684, 1712, 1738, 1765, 1791, 1817, 1842, 1868, 1894, 1919, 1943, 1968, 1991, 2015, 2038, 2062, 2084, 2107, 2129, 2149, 2172, 2193, 2213, 2234, 2255, 2268, 2295, 2313, 2332, 2351, 2388, 2424, 2461, 2495, 2530, 2564, 2596, 2629, 2658, 2687, 2687 + 156 * Math.ceil((inputValue - 500000) / 50000)];
  let i = 0;
  while (i < inputParam.length && inputParam[i] < inputValue) i += 1;
  return outputValue[i];
};

export const RVG_Value_Fee = (inputValue, minimalInputValue) => {
  if (minimalInputValue !== undefined && inputValue < minimalInputValue) return RVG_Value_Fee(minimalInputValue);
  if (inputValue <= 500) return 49;
  const inputParam = [2000, 10000, 25000, 50000, 200000, 500000, Infinity];
  const RVG_table = [
    [49, 500, 500, 39],
    [166, 2000, 1000, 56],
    [614, 10000, 3000, 52],
    [874, 25000, 5000, 81],
    [1279, 50000, 15000, 94],
    [2219, 200000, 30000, 132],
    [3539, 500000, 50000, 165],
  ];
  let i = 0;
  while (i < inputParam.length && inputValue > inputParam[i]) i += 1;
  return RVG_table[i][0] + Math.ceil((inputValue - RVG_table[i][1]) / RVG_table[i][2]) * RVG_table[i][3];
};

parser.functions.StBVV_TabelleA = StBVV_TabelleA;
parser.functions.StBVV_TabelleB = StBVV_TabelleB;
parser.functions.StBVV_TabelleC = StBVV_TabelleC;
parser.functions.StBVV_TabelleD_a = StBVV_TabelleD_a;
parser.functions.StBVV_TabelleD_b = StBVV_TabelleD_b;
parser.functions.RVG_Value_Fee = RVG_Value_Fee;
