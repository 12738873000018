export const priceKeys = {
  value: 'value',
  discountedValue: 'discountedValue',
  VAT: 'VAT',
  discountedVAT: 'discountedVAT',
  valueWithVAT: 'valueWithVAT',
  discountedValueWithVAT: 'discountedValueWithVAT',
};

export const CalculationMode = {
  ON_ACTUAL_COST: 'onActualCost',
  VALUE: 'value',
};

export const HighlightBindingness = {
  NO_HIGHLIGHT: 'noHighlight',
  DYNAMIC: 'dynamic',
  FIX_PRICED: 'fixPriced',
  ESTIMATED: 'estimated',
};
