export const inputFieldPaymentIntervalName = {
  monthly: 'monthly',
  oneOff: 'one-off',
  yearly: 'yearly',
};

export const inputFieldPaymentInterval = {
  monthly: 'monthly',
  oneOff: 'oneOff',
  yearly: 'yearly',
};

export const nbsp = '\xa0';

export const CalculatingEdge = {
  MIN: 'min',
  MAX: 'max',
};
