// eslint-disable-next-line import/named
import { Calc } from './exports';

(() => {
  // console.log();
  // console.log();
  // console.log();
  // console.log();
  if (!(process && process.env && process.env.npmDebug)) return;

  const j = {
    cart: [
      { _id: '5fa1373162f99d18ffbaf10a', discountId: null, inputFields: [], itemIds: [] },
      {
        _id: '5fa2c5f51e28870aedf626f5',
        discountId: null,
        inputFields: [
          { _id: '5fc8bddfeb51e929f42da4bb', value: 2 },
          { _id: '5fa51e79ce1a45075ccff595', value: 1 },
          { _id: '5fc8bf1f3972e42c426f5f8f', value: 4 },
          { _id: '5fa51e7bce1a45075ccff596', value: 2 },
          { _id: '5fc8bfd1a2d97d2ca17e41b2', value: '5fc8bfd0b3bd2e9eb7fdc508' },
          { _id: '5fa562cf1b782cabeebe7f5a', value: 3 },
        ],
        itemIds: ['5fa57282b3df14af5a8cd546', '5fa548a8ce1a45075ccff597', '5fa55f3d5a3bf4a7b9a17a1d', '5fa58049b3df14af5a8cd547'],
        startOfService: '2020-02',
      },
    ],
    categories: [
      { _id: '5fa2c5f51e28870aedf626f5', name: '11', createdBy: '5fa01838f8108432a89c7836', tenantId: '5fa01838f8108432a89c7834', createdAt: '2020-11-04T15:17:09.218Z', updatedAt: '2020-11-16T14:58:15.349Z', order: 7, __v: 0 },
      {
        _id: '5fa1373162f99d18ffbaf10a',
        name: 'lkjh lkjh lkj hlkj lkjh lkjh lkj hlkj lkjh lkjh lkj hlkj lkjh lkjh lkj hlkj lkjh lkjh lkj hlkj lkjh lkjh lkj hlkj lkjh lkjh lkj hlkj lkjh lkjh lkj hlkj lkjh lkjh lkj hlkj lkjh lkjh lkj hlkj lkjh lkjh lkj hlkj lkjh lkjh lkj hlkj lkjh lkjh lkj hlkj lkjh lkjh lkj hlkj lkjh lkjh lkj hlkj lkjh lkjh lkj hlkj lkjh lkjh lkj hlkj lkjh lkjh lkj hlkj lkjh lkjh lkj hlkj lkjh lkjh lkj hlkj lkjh lkjh lkj hlkj lkjh lkjh lkj hlkj lkjh lkjh lkj hlkj lkjh lkjh lkj hlkj lkjh lkjh lkj hlkj lkjh lkjh lkj hlkj lkjh lkjh lkj hlkj lkjh lkjh lkj hlkj lkjh lkjh lkj hlkj lkjh lkjh lkj hlkj lkjh lkjh lkj hlkj lkjh lkjh lkj hlkj lkjh lkjh lkj hlkj lkjh lkjh lkj hlkj lkjh lkjh lkj hlkj lkjh lkjh lkj hlkj lkjh lkjh lkj hlkj v',
        createdBy: '5fa01838f8108432a89c7836',
        tenantId: '5fa01838f8108432a89c7834',
        createdAt: '2020-11-03T10:55:45.886Z',
        updatedAt: '2020-11-16T14:58:11.609Z',
        order: 6,
        __v: 0,
      },
    ],
    items: [
      { _id: '5fa548a8ce1a45075ccff597', name: 'asdf', paymentInterval: 'monthly', pricingFormula: '#{{round}}(#{{round}}(1.5 + 5.1/2) -14 * @{{5fa562cf1b782cabeebe7f5a}})', categoryId: '5fa2c5f51e28870aedf626f5', createdBy: '5fa01838f8108432a89c7836', tenantId: '5fa01838f8108432a89c7834', createdAt: '2020-11-06T12:59:20.552Z', updatedAt: '2020-12-02T16:17:42.486Z', __v: 0, order: 2, minPrice: 6 },
      { _id: '5fa55f3d5a3bf4a7b9a17a1d', name: 'd', paymentInterval: 'oneOff', pricingFormula: '@{{5fa548a8ce1a45075ccff597}}+ @{{5fa51e79ce1a45075ccff595}} +#{{round}}(1/4)', categoryId: '5fa2c5f51e28870aedf626f5', createdBy: '5fa01838f8108432a89c7836', tenantId: '5fa01838f8108432a89c7834', createdAt: '2020-11-06T14:35:41.938Z', updatedAt: '2020-12-02T16:17:22.087Z', __v: 0, order: 3 },
      { _id: '5fa57282b3df14af5a8cd546', name: 'aadsfaaaaa!', paymentInterval: 'monthly', pricingFormula: '@{{5fa562cf1b782cabeebe7f5a}} + @{{5fa562cf1b782cabeebe7f5a}} / #{{round}}(1.52) + @{{5fc8bddfeb51e929f42da4bb}}  +1 +@{{5fa51e7bce1a45075ccff596}} ', categoryId: '5fa2c5f51e28870aedf626f5', createdBy: '5fa01838f8108432a89c7836', tenantId: '5fa01838f8108432a89c7834', createdAt: '2020-11-06T15:57:54.810Z', updatedAt: '2020-12-03T17:24:15.744Z', __v: 0, order: 1, minPrice: 0 },
      { _id: '5fa58049b3df14af5a8cd547', name: '1', paymentInterval: 'monthly', pricingFormula: '@{{5fa51e79ce1a45075ccff595}} /#{{round}} @{{5fa562cf1b782cabeebe7f5a}}+ #{{round}} @{{5fa51e79ce1a45075ccff595}}+ @{{5fa562cf1b782cabeebe7f5a}}', categoryId: '5fa2c5f51e28870aedf626f5', createdBy: '5fa01838f8108432a89c7836', tenantId: '5fa01838f8108432a89c7834', createdAt: '2020-11-06T16:56:41.076Z', updatedAt: '2020-11-10T13:27:45.159Z', __v: 0, order: 4 },
    ],
    discounts: [],
    inputFields: [
      { _id: '5fa51e79ce1a45075ccff595', name: 'input 11', categoryId: '5fa2c5f51e28870aedf626f5', createdBy: '5fa01838f8108432a89c7836', tenantId: '5fa01838f8108432a89c7834', createdAt: '2020-11-06T09:59:21.992Z', updatedAt: '2020-12-03T16:56:44.204Z', __v: 0, type: 'input', parentId: '5fa2c5f51e28870aedf626f5', parentType: 'category', order: 2 },
      { _id: '5fa51e7bce1a45075ccff596', name: 'input 2', categoryId: '5fa2c5f51e28870aedf626f5', createdBy: '5fa01838f8108432a89c7836', tenantId: '5fa01838f8108432a89c7834', createdAt: '2020-11-06T09:59:23.603Z', updatedAt: '2020-12-03T16:56:44.204Z', __v: 0, type: 'input', parentId: '5fa2c5f51e28870aedf626f5', parentType: 'category', order: 3 },
      { _id: '5fa562cf1b782cabeebe7f5a', name: 'asdf1', categoryId: '5fa2c5f51e28870aedf626f5', createdBy: '5fa01838f8108432a89c7836', tenantId: '5fa01838f8108432a89c7834', createdAt: '2020-11-06T14:50:55.122Z', updatedAt: '2020-12-03T16:56:44.204Z', __v: 0, type: 'input', parentId: '5fa2c5f51e28870aedf626f5', parentType: 'category', order: 4 },
      { _id: '5fc8bddfeb51e929f42da4bb', name: '1 in aadsfaa', type: 'input', options: [], categoryId: '5fa2c5f51e28870aedf626f5', parentId: '5fa57282b3df14af5a8cd546', parentType: 'categoryItem', createdBy: '5fa01838f8108432a89c7836', tenantId: '5fa01838f8108432a89c7834', createdAt: '2020-12-03T10:28:47.818Z', updatedAt: '2020-12-03T16:56:44.204Z', __v: 0, order: 1 },
      { _id: '5fc8bf1f3972e42c426f5f8f', name: '44', type: 'input', options: [], categoryId: '5fa2c5f51e28870aedf626f5', parentId: '5fa57282b3df14af5a8cd546', parentType: 'categoryItem', createdBy: '5fa01838f8108432a89c7836', tenantId: '5fa01838f8108432a89c7834', createdAt: '2020-12-03T10:34:07.342Z', updatedAt: '2020-12-03T16:56:44.204Z', __v: 0, order: 2 },
      { _id: '5fc8bfd1a2d97d2ca17e41b2', name: 'g', type: 'combo', options: [{ _id: '5fc8bfd0b3bd2e9eb7fdc508', name: 'df', order: '1', value: 23 }], categoryId: '5fa2c5f51e28870aedf626f5', parentId: '5fa57282b3df14af5a8cd546', parentType: 'categoryItem', createdBy: '5fa01838f8108432a89c7836', tenantId: '5fa01838f8108432a89c7834', createdAt: '2020-12-03T10:37:05.143Z', updatedAt: '2020-12-03T16:56:44.204Z', __v: 0, order: 3 },
    ],
  };
  console.log(Calc.calcCartTotal(j));
})();
